/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from "react";
import {
  writeBatch,
  doc,
  getFirestore,
  addDoc,
  collection,
  getDoc,
} from "firebase/firestore";

import {
  CheckCircleIcon,
  XCircleIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import {
  ClockIcon,
  CreditCardIcon,
  InformationCircleIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";

import UserContext from "./UserContext";
import Dropdown from "./Dropdown";
import Modal from "./Modal";
import BookSuccess from "./BookSuccess";
import { formatDate, formatTime } from "../methods/formatDates";
import MultiplePeople from "./MultiplePeople";
import Checkout from "./Checkout";
import useDocFetch from "../hooks/useDocFetch";
import useCollectionQuery from "../hooks/useCollectionQuery";
import ChooseAndUpdateProfile from "./ChooseAndUpdateProfile";
import { ORG_STATUS, USER_STATUS } from "../constants/booking-statuses";
import { cleanMemberProfileFields } from "@/methods/utilities";

export const moneyFormat = (value) => {
  if (value) {
    return `£${String(value).slice(0, String(value).length - 2)}.${String(
      value
    ).slice(String(value).length - 2)}`;
  } else {
    return "";
  }
};

const Booking = ({
  open,
  setOpen,
  defaultValues,
  eventData,
  dates,
  bookings,
  defaultStage,
  groupUnitDescription,
}) => {
  const db = getFirestore();

  const findCustomPrice = (key) => {
    return dates.find(
      (item) => item._id === key || item._id === key?.split("%")[1]
    )?.bookingDetails?.price;
  };

  const calculateBasicPrice = (values, price) => {
    const returnTotal = Object.keys(values).reduce((total, key) => {
      const customPrice = findCustomPrice(key);
      total += values[key] * (customPrice || price);
      return total;
    }, 0);
    return returnTotal;
  };

  const { bookingDetails = {} } = eventData;

  const exists = (obj) =>
    (!bookingDetails?.timeSlots || !bookingDetails?.showTimeSlots) &&
    bookings?.find(
      (item) => item.date === obj.start.toISOString().split("T")[0]
    );

  const noticeInsufficient = (obj) => {
    if (eventData?.bookingDetails?.advanceDays) {
      if (
        new Date(obj.start).getTime() <
        new Date().getTime() + eventData?.bookingDetails.advanceDays * 86400000
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const bookingDates = useCollectionQuery(`/Events/${eventData?._id}/Dates`, [
    ["start", ">", new Date()],
  ]);

  const bookingDateObj =
    bookingDates?.reduce((obj, value) => {
      obj[value._id] = value;
      return obj;
    }, {}) || {};

  const orgData = useDocFetch("Organisations", eventData.managedBy);
  const soldOut = (obj) => {
    const thisDate = bookingDateObj[obj.start.toISOString().split("T")[0]];
    if (
      Array.isArray(bookingDetails?.timeSlots) &&
      bookingDetails?.showTimeSlots
    ) {
      return false;
    } else {
      return thisDate?.booked >= eventData?.bookingDetails?.capacityTotal;
    }
  };

  const [disabled, setDisabled] = useState(false);

  const calculateOptions = (obj) => {
    if (eventData?.bookingDetails?.unit === "group") {
      return [
        {
          id: 0,
          value: 0,
        },
        { id: 1, value: 1 },
      ];
    }
    const capacity = eventData?.bookingDetails?.capacityTotal || 8;
    const booked =
      bookingDateObj[obj.start.toISOString().split("T")[0]]?.booked || 0;
    const optionArray = [...Array(Math.max(capacity - booked + 1, 0)).keys()];
    return optionArray.map((value) => ({
      id: value,
      value,
    }));
  };

  const [stage, setStage] = useState(defaultStage || "choose-dates");

  useEffect(() => {
    if (defaultStage) {
      setStage(defaultStage);
    }
  }, [defaultStage]);

  const { user } = useContext(UserContext);

  const [tempValues, setTempValues] = useState({});

  const [values, setValues] = useState({});

  useEffect(() => {
    if (defaultValues && Object.values(values).length === 0) {
      if (
        Array.isArray(eventData?.bookingDetails?.timeSlots) &&
        eventData?.bookingDetails?.showTimeSlots
      ) {
        setTempValues(defaultValues);
      } else {
        setValues(defaultValues);
      }
    }
  }, [defaultValues, eventData]);

  const handleDropdownChange = (name, value) => {
    if (
      Array.isArray(eventData?.bookingDetails?.timeSlots) &&
      eventData?.bookingDetails?.showTimeSlots
    ) {
      // TO DO: If number changes, slots booked should change
      const matchingValues = Object.keys(values).filter(
        (key) => key.includes(name) && values[key]
      );
      const newValues = matchingValues.reduce((obj, key) => {
        obj[key] = value.id;
        return obj;
      }, values);
      setValues(newValues);

      setTempValues({ ...tempValues, [name]: value.id });
    } else {
      setValues({ ...values, [name]: value.id });
    }
  };

  const handleBookAll = (value) => {
    const newValues = dates.reduce((obj, date) => {
      obj[date._id] = value.id;
      return obj;
    }, {});
    setValues(newValues);
  };

  // Question: what happens if we have more than one ticket, how does that get recording in insights?

  // Logic:
  // If Member Doc for this organisation exists, sign up with that Member Id
  // If no Member Doc exists, create a member doc (with just name and Email), then sign up
  // After registration, show registration form for the organisation

  // It's not possible to have 2 recurring event child Events that occur on the same day
  // Therefore, we could just look up bookings by day

  const isSlot =
    eventData?.bookingDetails?.showTimeSlots &&
    Array.isArray(eventData?.bookingDetails?.timeSlots);

  const formatBookingDoc = ({ start, end, member, slotId, bookingDetails }) => {
    const isChild = eventData.parentEventId ? true : false;
    const cleanMember = cleanMemberProfileFields(member || existingDetails || {});

    const bookData = {
      eventId: isChild ? eventData.parentEventId : eventData._id,
      user: member ? null : user.uid,
      booker: user.uid,
      date: start.toISOString().split("T")[0],
      memberId: member?._id || existingDetails?._id || null,
      customPrice: bookingDetails.price || null,
      created: new Date(),
      attending: true,
      start,
      userName: member?.name || user.displayName || null,
      userEmail: member?.email || user.email || null,
      end,
      managedBy: eventData.managedBy,
      type: member ? "guest" : "user",
      duration: end - start,
      profile: { ...cleanMember },
      name: eventData?.name?.text,
      bookingType: eventData?.bookingDetails?.registerInterest
        ? "applied"
        : "booked",
      userStatus: USER_STATUS.CONFIRMED,
      orgStatus: eventData?.bookingDetails?.registerInterest
        ? ORG_STATUS.PENDING
        : ORG_STATUS.CONFIRMED,
    };
    if (slotId) {
      bookData.slotId = slotId;
    }
    if (isChild) {
      bookData.childEventId = eventData._id;
    }
    return bookData;
  };

  const [error, setError] = useState(null);
  const [commenceSaving, setCommenceSaving] = useState(false);

  const [savedBookings, setSavedBookings] = useState([]);

  useEffect(() => {
    if (commenceSaving) {
      console.log("commencing saving");
      saveBookings();
      setCommenceSaving(false);
    }
  }, [commenceSaving]);

  const isFree = eventData?.bookingDetails?.free;
  const [orderId, setOrderId] = useState(null);
  const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);

  const saveBookings = async (otherMembers) => {
    const chosenDates = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => {
        // If this is a slot booking, split the key and save the slotId

        const basic = dates.find(
          (item) => item._id === key || item._id === key?.split("%")[1]
        );

        if (isSlot) {
          basic.slotId = key.split("%")[0];
          const chosenSlot = eventData?.bookingDetails?.timeSlots.find(
            (item) => item.id === basic.slotId
          );
          // Read start, end and duration from the slot, not the event
          const year = basic.start.getFullYear();
          const monthIndex = basic.start.getMonth();
          const day = basic.start.getDate();
          basic.start = new Date(
            year,
            monthIndex,
            day,
            chosenSlot?.start?.slice(0, 2),
            chosenSlot?.start?.slice(3, 5),
            0,
            0
          );
          basic.end = new Date(
            year,
            monthIndex,
            day,
            chosenSlot?.end?.slice(0, 2),
            chosenSlot?.end?.slice(3, 5),
            0,
            0
          );
          basic.duration = basic.end - basic.start;
        }
        if (values[key] > 1) {
          // When i is highest, otherMembers[0] will be null (ie is the booker)
          return [...Array(values[key]).keys()].map((obj, i) => ({
            ...basic,
            member: otherMembers[i] || null,
          }));
        }
        return basic;
      });

    const toSaveBooking = chosenDates.flat().map(formatBookingDoc);

    setSavedBookings(toSaveBooking);
    // bookEvent(toSaveBooking);

    if (isFree || otherPaymentMethod) {
      const batch = writeBatch(db);

      toSaveBooking.map((item) => {
        console.log({ item });
        const bookRef = doc(collection(db, "Bookings"));
        batch.set(bookRef, item);
      });

      await batch
        .commit()
        .then(() => {
          setError(null);
          setStage("success");
        })
        .catch((err) => {
          setError(err);
          setCommenceSaving(false);
          setOtherPaymentMethod(false);
        });
    } else {
      let fullPrice;
      if (eventData?.bookingDetails?.price) {
        fullPrice = eventData?.bookingDetails?.price;
      }

      await addDoc(collection(db, "Orders"), {
        bookings: toSaveBooking?.map((booking) => ({
          ...booking,
          fullPrice,
        })),
        values,
        members: otherMembers || [],
        created: new Date(),
        uid: user.uid,
      })
        .then((result) => {
          if (result) {
            setOrderId(result.id);
          }
        })
        .catch((err) => {
          setError(err);
          setOtherPaymentMethod(false);
          setCommenceSaving(false);
        });
      setStage("confirm-payment");
    }
  };

  const handleBookEvent = async () => {
    if (eventData?.bookingDetails?.formId) {
      setStage("extra-details");
    } else if (Math.max(...Object.values(values)) > 1) {
      setStage("multiple-people");
    } else {
      saveBookings();
    }
  };

  const handleSetOpen = () => {
    setStage("choose-dates");
    setError(null);
    setOpen(false);
    setValues({});
  };

  const [existingDetails, setExistingDetails] = useState(null);

  const handleFinishExtraDetails = async (personalDataId) => {
    // fetch doc from PersonalData
    const personalData = await getDoc(doc(db, "PersonalData", personalDataId));
    const personalDataData = { ...personalData.data(), _id: personalData.id };
    setExistingDetails(personalDataData);

    // If there are more than 1 attendees
    if (Math.max(...Object.values(values)) > 1) {
      setStage("multiple-people");
    } else {
      // If not, set commence saving as true
      setCommenceSaving(true);
    }
  };

  const [otherAttendees, setOtherAttendees] = useState([]);

  const handleFinishExtraPeople = async () => {
    try {
      const batch = writeBatch(db);
      const members = otherAttendees.slice(1).map((item) => {
        const memberRef = doc(collection(db, "PersonalData"));
        batch.set(memberRef, {
          "Full Name": [item.name],
          Email: [item.email],
          managedBy: eventData.managedBy,
          userCreated: true,
          createdBy: user?.uid,
        });
        return { _id: memberRef.id, name: item.name, email: item.email };
      });

      await batch.commit();
      saveBookings(members);
    } catch (err) {
      console.log({ err });
      setError(err);
    }
  };

  const handleSlotClick = (e, slot, obj) => {
    e.preventDefault();
    e.stopPropagation();

    setValues({
      ...values,
      [`${slot.id}%${obj._id}`]: values[`${slot.id}%${obj._id}`]
        ? 0
        : tempValues[obj._id],
    });
  };

  useEffect(() => {
    if (values) {
      Object.keys(values)?.filter((key) => values?.[key] > 0).length === 0
        ? setDisabled(true)
        : setDisabled(false);
    }
  }, [values, eventData]);

  const timeSlots = (obj) => {
    if (!obj.bookingDetails?.timeSlots || !obj.bookingDetails.showTimeSlots) {
      return <p className="text-xs text-gray-800 mt-1">{formatTime(obj)}</p>;
    }
    const startDate = new Date(obj.start);
    const weekday = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;

    if (!tempValues[obj._id]) {
      return (
        <div className="text-sm text-gray-800 mt-1">
          {
            obj.bookingDetails.timeSlots?.filter(
              (slot) =>
                !slot?.weekdays ||
                slot.weekdays.length === 0 ||
                slot.weekdays.includes(weekday)
            )?.length
          }{" "}
          time slots
        </div>
      );
    }

    const slotBooked = (slot) => {
      const bookingDates =
        bookingDateObj[obj.start.toISOString().split("T")[0]];

      if (
        !bookingDates ||
        !bookingDates[slot.id] ||
        bookingDates?.[slot.id]?.booked < bookingDetails?.capacityTotal
      ) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <div className="flex flex-wrap mt-2">
        {obj.bookingDetails.timeSlots
          ?.filter(
            (slot) =>
              !slot?.weekdays ||
              slot.weekdays.length === 0 ||
              slot.weekdays.includes(weekday)
          )
          .map((slot) => {
            // console.log("thisDate.slots", thisDate?.slots);
            return (
              <button
                onClick={(e) => handleSlotClick(e, slot, obj)}
                disabled={slotBooked(slot)}
                className={`${
                  values[`${slot.id}%${obj._id}`]
                    ? "bg-indigo-600 text-white hover:bg-indigo-700"
                    : "hover:bg-gray-50"
                } ${
                  slotBooked(slot)
                    ? "bg-gray-300 cursor-not-allowed text-gray-600 line-through hover:bg-gray-300"
                    : "bg-white"
                } 
            mr-2 mb-2 rounded-sm border-greyClear border py-1 px-2 text-sm`}
              >
                {slot.start} - {slot.end}
              </button>
            );
          })}
      </div>
    );
  };

  const allowMultiple = false;
  const RenderAction = (props) => {
    const {
      disabled,
      disabledText,
      label,
      name,
      onChange,
      defaultSelected,
      value,
      options,
    } = props;
    if (allowMultiple) {
      return <Dropdown {...props} />;
    } else
      return (
        <>
          {disabled ? (
            <div className="bg-gray-100 border rounded text-gray-600 text-sm font-medium py-2 px-4 border-gray-200">
              {disabledText}
            </div>
          ) : (
            <input
              type="checkbox"
              checked={value > 0}
              className="focus:ring-indigo-500 h-6 w-6 text-primary border-greyClear rounded"
              onChange={(e) => {
                if (e.target.checked) {
                  onChange({ id: 1, value: 1 });
                } else {
                  onChange({ id: 0, value: 0 });
                }
              }}
            />
          )}
        </>
      );
  };

  console.log({ dates });

  const checkoutProps = {
    description: eventData?.name?.text,
    orgStripeAccountId: orgData?.stripeAccountId,
    eventId: eventData?._id,
    items:
      values &&
      Object.keys(values).map((key) => ({
        eventId:
          dates?.find(
            (item) => item?._id === key || item._id === key?.split("%")[1]
          )?._id || null,
        images: [eventData?.bookingDetails?.images?.[0]?.src],
        name: eventData?.name?.text,
        quantity: values[key],
        description: dates
          .find((obj) => obj._id === key)
          ?.start.toLocaleDateString("en-GB", {
            weekday: "short",
            month: "short",
            day: "numeric",
          }),
      })),
    orderId,
  };

  return (
    <Modal
      className={stage === "choose-dates" ? "lg:max-w-5xl" : "lg:max-w-2xl"}
      open={open}
      setOpen={handleSetOpen}
    >
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There was an error with your booking
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc pl-5 space-y-1">
                  <li>{error.message}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {stage === "choose-dates" ? (
        <form
          className="md:grid grid-cols-3 w-full"
          onSubmit={(e) => {
            e.preventDefault();
            handleBookEvent();
          }}
        >
          <div className="col-span-2">
            <h1 className="p-2 border-b border-gray-200 text-md text-center w-full">
              Booking
            </h1>
            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      There was an error with your booking
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul role="list" className="list-disc pl-5 space-y-1">
                        <li>
                          You don't have permission to book this event. Please
                          contact {eventData?.bookingDetails?.orgName}{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="px-6 py-4 overflow-y-scroll lg:pb-24">
              <h2 className="text-2xl font-medium">
                {bookingDetails?.bookAll ? "Confirm Booking" : "Select Dates"}
              </h2>
              {groupUnitDescription && (
                <div className="text-blue-500 rounded-md mt-2 flex items-center">
                  <InformationCircleIcon className="h-5 w-5  mr-2" />
                  {`You are booking for a group of ` +
                    groupUnitDescription?.toLowerCase()}
                </div>
              )}
              <div
                className="lg:pb-36"
                style={{ maxHeight: "calc(100vh - 250px)" }}
              >
                {bookingDetails?.bookAll ? (
                  <div className="flex py-4 border-b border-gray-300 items-center justify-between">
                    <div>
                      {dates.map((obj) => (
                        <div>{formatDate(obj)}</div>
                      ))}
                    </div>
                    {dates?.[0] && (
                      <RenderAction
                        disabledText={
                          exists(dates[0]) ? "Already Booked." : "No spaces."
                        }
                        disabled={
                          noticeInsufficient(dates[0]) ||
                          exists(dates[0]) ||
                          soldOut(dates[0])
                        }
                        label="Ticket Quantity"
                        name={dates[0]._id}
                        onChange={(value) => handleBookAll(value)}
                        defaultSelected={0}
                        value={
                          Array.isArray(eventData?.bookingDetails?.timeSlots) &&
                          eventData?.bookingDetails?.showTimeSlots
                            ? tempValues[dates?.[0]._id]
                            : values[dates?.[0]._id]
                        }
                        options={calculateOptions(dates?.[0])}
                      />
                    )}
                  </div>
                ) : (
                  dates.map((obj, i) => (
                    <div className="flex py-4 border-b border-gray-300 items-center justify-between">
                      <div>
                        <div className="font-medium">
                          {formatDate(obj)}
                          {obj?.bookingDetails?.price !== bookingDetails?.price
                            ? ` - ${moneyFormat(obj?.bookingDetails?.price)}`
                            : ""}
                        </div>

                        {timeSlots(obj)}
                      </div>
                      <RenderAction
                        disabledText="No spaces"
                        disabled={
                          noticeInsufficient(obj) || exists(obj) || soldOut(obj)
                        }
                        label="Ticket Quantity"
                        name={obj._id}
                        onChange={(value) =>
                          handleDropdownChange(obj._id, value)
                        }
                        defaultSelected={0}
                        value={
                          Array.isArray(eventData?.bookingDetails?.timeSlots) &&
                          eventData?.bookingDetails?.showTimeSlots
                            ? tempValues[obj._id]
                            : values[obj._id]
                        }
                        options={calculateOptions(obj)}
                      />
                    </div>
                  ))
                )}

                <div className="lg:h-32" />
              </div>
            </div>
          </div>
          <div className="bg-gray-100 border-l border-gray-200">
            <img
              src={eventData?.bookingDetails?.images?.[0]?.src}
              className="h-48 hidden md:block w-full object-cover "
              alt={eventData?.bookingDetails?.images?.[0]?.alt}
            />
            <div className="p-4">
              <h2 className="font-medium text-md">Summary</h2>
              <ul className="w-full mb-4">
                {Object.keys(values)
                  .filter((key) => values[key])
                  .map((key) => (
                    <li className="text-gray-700 text-sm grid grid-cols-2 py-4 border-b border-gray-200">
                      <div>
                        {dates
                          .find(
                            (obj) =>
                              obj._id === key.split("%")[1] || obj._id === key
                          )
                          ?.start.toLocaleDateString("en-GB", {
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                          })}
                        {key.split("%")[1] && (
                          <p className="text-gray-600 text-sm font-medium">
                            {
                              eventData?.bookingDetails?.timeSlots?.find(
                                (item) => item.id === key.split("%")[0]
                              )?.start
                            }{" "}
                            -{" "}
                            {
                              eventData?.bookingDetails?.timeSlots?.find(
                                (item) => item.id === key.split("%")[0]
                              )?.end
                            }
                          </p>
                        )}
                      </div>
                      <div className="text-right">{values[key]}</div>
                    </li>
                  ))}
              </ul>
              {!bookingDetails?.bookAll &&
                Object.values(values).filter((item) => item).length > 0 && (
                  <div className="pb-4 font-medium text-xl grid grid-cols-2">
                    Total:{" "}
                    <div className="text-right">
                      {Object.values(values).reduce(
                        (total, item) => total + item,
                        0
                      )}
                    </div>
                  </div>
                )}
              <button
                disabled={disabled}
                type="submit"
                className={`bg-indigo-600 w-full border border-transparent rounded-md py-2 flex items-center 
              justify-center text-md font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                disabled
                  ? "bg-gray-300 hover:bg-gray-300 text-gray-600 cursor-not-allowed"
                  : "bg-indigo-600"
              }`}
              >
                {eventData?.bookingDetails?.registerInterest
                  ? "Register Interest"
                  : "Book"}
              </button>
            </div>
          </div>
        </form>
      ) : stage === "extra-details" ? (
        <>
          <h1 className="p-2 border-b border-gray-200 text-md text-center w-full">
            Extra Information
          </h1>
          <div className="p-4">
            <h2 className="text-2xl font-medium">Add extra details</h2>
            <p className="text-gray-700 text-sm mb-4">
              {existingDetails?._id
                ? `Because you've verified your email, we've found some information ${eventData?.bookingDetails?.orgName} had already saved about you. You can update and add to it here.`
                : `${eventData?.bookingDetails?.orgName} need this information for their reporting purposes. They won't share any of your personal details with anyone outside the organisation without your express permission.`}
            </p>
            <ChooseAndUpdateProfile
              onFinish={handleFinishExtraDetails}
              loading={commenceSaving}
              eventData={eventData}
              orgId={eventData?.managedBy}
              formId={eventData?.bookingDetails?.formId}
            />
          </div>
        </>
      ) : stage === "multiple-people" ? (
        <MultiplePeople
          handleSave={handleFinishExtraPeople}
          values={values}
          onChange={(arr) => setOtherAttendees(arr)}
        />
      ) : stage === "confirm-payment" ? (
        <div>
          <h1 className="p-2 border-b border-gray-200 text-md text-center w-full">
            Confirm Payment
          </h1>
          <div className="bg-white">
            <div className="max-w-2xl mx-auto py-6 px-4  sm:px-6">
              <form className="mt-6">
                <section aria-labelledby="cart-heading">
                  <h2
                    className="text-lg mb-2 font-medium text-gray-900"
                    id="cart-heading"
                  >
                    Order summary
                  </h2>

                  <ul
                    role="list"
                    className="border-t border-b border-gray-200 divide-y divide-gray-200"
                  >
                    {Object.keys(values)
                      .filter((key) => values[key])
                      .map((key) => (
                        <li key={key} className="flex py-6">
                          <div className="flex-shrink-0">
                            <img
                              src={eventData?.bookingDetails?.images?.[0]?.src}
                              alt={
                                eventData?.bookingDetails?.images?.[0]?.alt ||
                                ""
                              }
                              className="w-16 h-16 rounded-md object-center object-cover"
                            />
                          </div>

                          <div className="ml-6 flex-1 flex flex-col sm:ml-6">
                            <div>
                              <div className="flex justify-between">
                                <h4 className="text-sm">
                                  <div className="font-medium text-gray-700 hover:text-gray-800">
                                    {dates
                                      .find(
                                        (obj) =>
                                          obj._id === key.split("%")[1] ||
                                          obj._id === key
                                      )
                                      ?.start.toLocaleDateString("en-GB", {
                                        weekday: "short",
                                        month: "short",
                                        day: "numeric",
                                      })}
                                  </div>
                                </h4>

                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {findCustomPrice(key)
                                      ? moneyFormat(findCustomPrice(key))
                                      : moneyFormat(
                                          eventData?.bookingDetails?.price
                                        )}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500 text-right">
                                    x {values[key]}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="flex-1 flex items-end justify-between">
                              <p className="flex items-center text-sm text-gray-700 space-x-2">
                                <CheckIcon
                                  className="flex-shrink-0 h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />

                                <span>Available</span>
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </section>

                {/* Order summary */}
                <section aria-labelledby="summary-heading" className="mt-10">
                  <h2 id="summary-heading" className="sr-only">
                    Order summary
                  </h2>

                  <div>
                    <dl className="space-y-4">
                      <div className="flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">
                          Total
                        </dt>
                        <dd className="ml-4 text-base font-medium text-gray-900">
                          {moneyFormat(
                            calculateBasicPrice(
                              values,
                              eventData?.bookingDetails?.price
                            )
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div className="mt-10 w-full flex space-x-4">
                    {(!eventData?.bookingDetails?.paymentMethods ||
                      eventData?.bookingDetails?.paymentMethods?.includes(
                        "card"
                      )) && (
                      <Checkout
                        {...checkoutProps}
                        paymentMethod="card"
                        text={
                          <div className="flex items-center space-x-2 justify-center">
                            <CreditCardIcon className="h-4" />
                            <span>Pay by Card</span>
                          </div>
                        }
                      />
                    )}

                    {eventData?.bookingDetails?.paymentMethods?.includes(
                      "bank-transfer"
                    ) && (
                      <Checkout
                        {...checkoutProps}
                        secondary
                        paymentMethod="bank-transfer"
                        text={
                          <div className="flex items-center space-x-2 justify-center">
                            <BuildingLibraryIcon className="h-4" />
                            <span>Request an Invoice</span>
                          </div>
                        }
                      />
                    )}

                    {eventData?.bookingDetails?.paymentMethods?.includes(
                      "other"
                    ) && (
                      <div className="w-full">
                        <button
                          type="button"
                          onClick={() => {
                            setOtherPaymentMethod(true);
                            setCommenceSaving(true);
                          }}
                          className={`
                          ${`bg-indigo-600 border-transparent rounded-md shadow-sm  text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500`} w-full border rounded py-3 px-4 text-base font-medium`}
                        >
                          <div className="flex items-center space-x-2 justify-center">
                            <ClockIcon className="h-4" />
                            <span>Pay Later</span>
                          </div>
                        </button>
                        <div className="mt-2 text-gray-800 text-sm text-center">
                          {eventData?.bookingDetails?.otherPaymentMethod}
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="p-2 border-b border-gray-200 text-md text-center w-full">
            Nice one.
          </h1>
          <div className="p-4">
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    {eventData?.bookingDetails?.registerInterest
                      ? "Your interest has been registered"
                      : "Booked"}
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    {!eventData?.bookingDetails?.registerInterest ? (
                      <p>
                        {eventData?.bookingDetails?.orgName} will check you in
                        when you arrive at the event. If you can't attend,
                        please let them know before hand by cancelling your
                        booking.
                      </p>
                    ) : (
                      <p>
                        {eventData?.bookingDetails?.orgName} will contact your
                        shortly about the status of your booking.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <h2 className="text-xl font-medium my-4">
              Add{" "}
              {Object.keys(values).filter((key) => values[key]).length > 1
                ? "these events"
                : "this event"}{" "}
              to your calendar
            </h2>

            {savedBookings.map((item) => (
              <li className="text-sm flex justify-between items-center py-4 border-t border-gray-200">
                <div className="font-medium text-md">
                  {item.start.toLocaleDateString("en-GB", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  })}
                </div>
                <BookSuccess
                  text="badkljfasdfjasdf"
                  event={{
                    title: eventData?.name?.text,
                    description: eventData?.bookingDetails?.description || "",
                    start: item.start,
                    duration: [item.duration / 3600000, "hour"],
                  }}
                />
              </li>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Booking;
